import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const UnenrolEmployeeModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      size='md'
      header='nmbr.employees.directory.Unenrol from payroll'
      subHeader='nmbr.employees.directory.Are you sure you want to unenrol this employee from payroll?'
      body='nmbr.employees.directory.They will be unenrolled as of the end of the last run payroll, and will not appear in your next payroll cycle.'
      isOpen={modalOpen}
      onHide={() => uiState.closeUnenrolEmployeeModal()}
    >
      <ModalDangerButtons
        onSave={() => uiState.unenrolEmployee()}
        saveCaption={'nmbr.employees.directory.Unenrol'}
        onCancel={() => uiState.closeUnenrolEmployeeModal()}
      />
    </AlertModal>
  );
});

export default UnenrolEmployeeModal;
