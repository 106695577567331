import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, RadioButtonGroup, PanelRadioButton, ObjectSelect2, QuickTip} from 'components';

const ApprovalChangeWarning = observer(({uiState}) => {
  const {willApprovePendingRequests, willModifySecondStageRequests, willApproveSecondStageRequests} = uiState;

  if (willApprovePendingRequests) {
    return (
      <QuickTip
        trait='caution'
        paragraphs={[
          t('time_off.policy.edit.APPROVAL_CHANGE_WARNING_PENDING_REQUESTS')
        ]}
      />
    );
  } else if (willModifySecondStageRequests) {
    return (
      <QuickTip
        trait='caution'
        paragraphs={[
          t('time_off.policy.edit.APPROVAL_CHANGE_WARNING_MODIFY_SECOND_STAGE_REQUESTS')
        ]}
      />
    );
  } else if (willApproveSecondStageRequests) {
    return (
      <QuickTip
        trait='caution'
        paragraphs={[
          t('time_off.policy.edit.APPROVAL_CHANGE_WARNING_APPROVE_SECOND_STAGE_REQUESTS')
        ]}
      />
    );
  } else {
    return null;
  }
});

const ApprovalForm = observer(({uiState}) => {
  const {typePolicy, assignee, availableAssignees, errors, displayApprovalChangeWarning} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('time_off.policy.edit.APPROVALS', {type: typePolicy.type.name})}/>
      {displayApprovalChangeWarning && <div className='mb2'><ApprovalChangeWarning uiState={uiState}/></div>}
      <RadioButtonGroup
        className={'clearfix table'}
        name='approvalType'
        selectedValue={typePolicy.approvalType}
        onChange={(name, value) => typePolicy.merge({approvalType: value, secondStageApprovalType: null, secondStageApprovalAssigneeUser: null})}
      >
        <PanelRadioButton
          value='single'
          label='time_off.policy.edit.approvals.Single approval'
          description='time_off.policy.edit.approvals.SINGLE_EXPLANATION'
        />
        <PanelRadioButton
          value='two_stage'
          label='time_off.policy.edit.approvals.Two-step approval'
          description='time_off.policy.edit.approvals.TWO_STAGE_EXPLANATION'
        >
          <ObjectSelect2
            value={assignee}
            onChange={assignee => uiState.selectAssignee(assignee)}
            searchable={true}
            errorMessage={errors.secondStageApprovalType}
            items={availableAssignees}
          />
        </PanelRadioButton>
        <PanelRadioButton
          value='auto'
          label='time_off.policy.edit.approvals.Auto approval'
          description='time_off.policy.edit.approvals.AUTO_EXPLANATION'
        />
      </RadioButtonGroup>
    </Panel>
  );
});

export default ApprovalForm;
